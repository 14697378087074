<template>
  <div class="sign-in">
    <sign-in-form
      v-if="currentStep === steps.signIn"
      :is-loading="isLoading"
      @submit="onSignIn"
      @reg="onReg"
    />

    <x-loader
      v-else-if="isLoading"
      size="m"
    />

    <enter-code-form
      v-else-if="currentStep === steps.twofa"
      v-model="token_2fa"
      :error-message="verifyError"
      :two-fa-type="twoFaType"
      @submit="on2FaVerify"
      @back="onGoBack"
    >
      Двухфакторная аутентификация
    </enter-code-form>

    <form-dialog v-if="showSendModal" @close="showSendModal = false"/>
  </div>
</template>

<script>
import SignInForm from '@/components/SignIn/SignInForm.vue'
import EnterCodeForm from '@/components/Common/EnterCodeForm.vue'
import FormDialog from '@/components/modals/FormDialog.vue'
import {
  redirectToReturnUrl,
  getCabinetType,
  removeCabinetType
} from '@/utils/system'
import { pushDataLayerObject } from '@/metrics/gtm'

export default {
  components: {
    SignInForm,
    EnterCodeForm,
    FormDialog
  },

  data: () => ({
    showSendModal: false,
    steps: {
      signIn: 1,
      twofa: 2
    },
    credentials: {
      login: '',
      password: ''
    },
    token_2fa: '',
    twoFaType: '',
    verifyError: '',
    currentStep: 1,
    isLoading: false
  }),

  computed: {
    metricsEvents () {
      return {
        reg: this.$route.meta?.metricsEvents?.reg,
        visit: this.$route.meta?.metricsEvents?.visit,
        login: this.$route.meta?.metricsEvents?.login
      }
    }
  },

  methods: {
    onGoBack () {
      this.currentStep--
    },

    onReg () {
      this.$metrics.hit(this.metricsEvents.reg)
      this.showSendModal = true
    },

    async onSignIn (payload) {
      this.$metrics.hit(this.metricsEvents.login)

      try {
        this.isLoading = true

        this.credentials = payload
        const { login, password, token2fa } = payload

        const res = await this.$authService.login({
          login,
          raw_password: password,
          token_2fa: token2fa,
          source_type: getCabinetType()
        })

        const {
          auth_type: twoFaType,
          access_token: token,
          requires2Fa
        } = res

        this.twoFaType = twoFaType

        if (requires2Fa) {
          this.currentStep++
          return
        }

        removeCabinetType()

        if (!token) {
          this.$toast.error('Произошла ошибка. Попробуйте позже')
          return
        }

        pushDataLayerObject({
          event: 'sign_in',
          eventModel: {
            email: this.credentials.login
          }
        })

        setTimeout(() => {
          redirectToReturnUrl(token)
        }, 200)
      } finally {
        this.isLoading = false
      }
    },

    on2FaVerify () {
      this.onSignIn({
        ...this.credentials,
        token2fa: this.token_2fa
      })
    }
  },

  mounted () {
    this.$metrics.hit(this.metricsEvents.visit)
  }
}
</script>

<style lang="stylus" scoped>

</style>
