import axios from 'axios'
import { getRootUrl } from '@/utils/environment'

export function getStatistics () {
  return axios.get(`${getRootUrl()}/seller_cabinet/v1/mmp-landing/users`)
}

export function sendFeedbackData (payload) {
  return axios.post(`${getRootUrl()}/seller_cabinet/v1/mmp-landing/contacts`, payload)
}
