<template>
  <div class="overlay">
  <div class="form-dialog" :class="{ 'form-dialog_padded': showSuccess }">
    <a class="close" @click="$emit('close')">X</a>
    <div v-if="!showSuccess" class="form-dialog__title">
      <h3>Оставьте данные для обратной связи</h3>
    </div>
    <div class="form-dialog__content">
      <validation-observer
        v-if="!showSuccess"
        ref="vObserver"
        v-slot="{ handleSubmit, invalid }"
      >
        <form class="form" @submit.prevent="handleSubmit(onSubmit)">
          <fieldset class="form-fields-group mb-10">
            <ul class="form-fields-list">
              <li class="form-fields-list__item">
                <x-form-item
                  v-slot="validationData"
                  :rules="'required'"
                  vid="name"
                  name="Имя"
                  label="Имя"
                >
                  <x-input
                    id="name"
                    v-bind="validationData"
                    placeholder="Ваше имя"
                    v-model="fields.name"
                  />
                </x-form-item>
              </li>
              <li class="form-fields-list__item">
                <x-form-item
                  v-slot="validationData"
                  :rules="'email|required'"
                  vid="email"
                  name="Почта"
                  label="Почта"
                >
                  <x-input
                    id="mail"
                    v-bind="validationData"
                    placeholder="your_mail@index.ru"
                    v-model="fields.email"
                  />
                </x-form-item>
              </li>
              <li class="form-fields-list__item">
                <x-form-item
                  v-slot="validationData"
                  :rules="'phone|required'"
                  vid="phone_number"
                  name="Телефон"
                  label="Телефон"
                >
                  <x-input
                    id="phone"
                    v-bind="validationData"
                    v-mask="'+# (###) ###-##-##'"
                    placeholder="+7 995 888 22 22"
                    v-model="fields.phone_number"
                  />
                </x-form-item>
              </li>
            </ul>
          </fieldset>
          <div class="form-dialog__buttons">
            <button type="button" class="cancel" @click="$emit('close')">Закрыть</button>
            <button type="submit" class="send" :disabled="invalid || loading">Отправить</button>
          </div>
        </form>
      </validation-observer>
      <div v-else id="consultation-success-message" class="form-dialog__success">
        <x-icon name="check-circle" color="success" />
        <h4 class="mb-2">Готово!</h4>
        <p class="mb-4">Мы свяжемся с вами в ближайшее время</p>
      </div>
      <button v-if="showSuccess" type="button" class="cancel-button" @click="$emit('close')">Закрыть</button>
      <p id="consultation-form_other-error" class="form-dialog__error"></p>
    </div>
  </div>
</div>
</template>

<script>
import { sendFeedbackData } from '@/api/unicornServices'

export default {
  data () {
    return {
      showSuccess: false,
      loading: false,
      invalidMessage: {},
      fields: {
        name: '',
        email: '',
        phone_number: ''
      }
    }
  },
  watch: {
    invalidMessage: {
      handler (err) {
        if (!err) return
        // eslint-disable-next-line
        Object.keys(err).forEach( fieldKey => {
          this.$refs.vObserver.setErrors({
            [fieldKey]: err[fieldKey][0] || 'Что то пошло не так'
          })
        })
      }
    }
  },
  methods: {
    async onSubmit () {
      this.loading = true
      try {
        await sendFeedbackData(this.fields)
        this.showSuccess = true
      } catch (e) {
        this.invalidMessage = e.response?.data?.errorMessage
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.overlay
  display flex
  justify-content center
  align-items center
  width 100vw
  height 100vh
  position fixed
  z-index 100
  left 0
  top 0
  padding 10px
  background rgba(0,0,0,0.6)

.form-dialog
  display flex
  flex-direction column
  justify-content center
  align-items center
  background white
  width 576px
  padding 64px 36px
  border-radius 8px
  position relative

  & >>> .x-form-item__errors
    text-align-last left

  &_padded
    padding 40px 36px

  &__success
    display flex
    flex-direction column
    align-items center

    & .x-icon-check-circle
      width 72px
      height 72px
      margin-bottom 26px

    h4
      font-size 20px
      font-weight 700
      line-height 24px
      text-align center

    p
      font-size 14px
      font-weight 400
      line-height 18px
      text-align center

  .close
    position absolute
    top 15px
    right 15px
    color #A3A3AC
    font-size 20px
    cursor pointer

  &__error
    padding 4px 0
    color red
    margin-bottom 4px

  &__title
    width 75%
    margin-bottom 20px
    h3
      text-align left
      color #3C3C47
      font-weight bold
      font-size 20px
      line-height 24px

  &__content
    width 75%

  &__buttons
    display flex
    margin-left -12px

    @media (max-width 768pc)
      margin-left 0

    .form-button
      width 50%
      padding 9px 0
      margin-left 12px
      border-radius 4px
      border none

    .send
      width 50%
      padding 9px 0
      margin-left 12px
      border-radius 4px
      border none
      color white
      background #5B40FF
      cursor pointer
      height 40px
      font-size 15px
      letter-spacing: .2px

      &:disabled
        // background #F2F2F4
        opacity .5
        cursor not-allowed

    .cancel
      width 50%
      padding 9px 0
      border-radius 4px
      border none
      color #3C3C47
      background #F2F2F4
      cursor pointer
      height 40px
      font-size 15px
      letter-spacing: .2px

.form
  padding 0px

.cancel-button
  width 100%
  padding 9px 0
  border-radius 4px
  border none
  color #3C3C47
  background #F2F2F4
  cursor pointer
  height 40px
  font-size 15px
  letter-spacing: .2px
</style>
