<template>
  <div class="sign-in-form">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <form
        class="form"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <fieldset class="form-fields-group">
          <legend class="form-fields-group__title">
            Вход в кабинет продавца
          </legend>

          <ul class="form-fields-list">
            <li class="form-fields-list__item-group">
              <login-field
                v-model="form.login"
              />
            </li>

            <li class="form-fields-list__item">
              <x-form-item
                v-slot="validationData"
                rules="required"
                name="Пароль"
                label="Введите пароль"
              >
                <x-input
                  id="password"
                  v-model="form.password"
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="Пароль"
                  v-bind="validationData"
                  :append-icon="showPassword ? 'eye-off' : 'eye-on'"
                  @click:append="showPassword = !showPassword"
                />
              </x-form-item>
            </li>

            <li class="form-fields-list__item sign-in-form__actions">
              <x-checkbox
                v-model="rememberMe"
                label="Запомнить меня"
              />

              <x-btn
                ghost
                :to="{ name: 'auth.recovery' }"
                :metrics="metricsEvents.forgotPassword"
              >
                Забыли пароль?
              </x-btn>
            </li>

            <li class="form-fields-list__item">
              <x-btn
                type="submit"
                block
                :loading="isLoading"
              >
                Войти
              </x-btn>
            </li>

            <li id="btn-registration" class="form-fields-list__item">
              <!-- :to="{ name: 'auth.sign.up' }" -->
              <x-btn
                ghost
                type="button"
                @click="onRegClick"
              >
                Зарегистрироваться
              </x-btn>
            </li>
          </ul>
        </fieldset>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import LoginField from '@/components/Common/LoginField.vue'
import '@/styles/form.styl'

export default {
  components: {
    LoginField
  },

  props: {
    isLoading: Boolean
  },

  data: () => ({
    form: {
      login: {
        phone: '',
        email: ''
      },
      password: ''
    },
    rememberMe: false,
    showPassword: false
  }),

  computed: {
    computedNumber () {
      return this.form.login.phone.replace(/[()-]/g, '')
    },

    metricsEvents () {
      return {
        forgotPassword: this.$route.meta?.metricsEvents?.forgotPassword
      }
    }
  },

  methods: {
    onSubmit () {
      const { login, password } = this.form

      this.$emit('submit', {
        login: login.email || this.computedNumber,
        password,
        rememberMe: this.rememberMe
      })
    },
    onRegClick () {
      this.$emit('reg')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .sign-in-form
    &__actions
      display flex
      justify-content space-between
      margin-top 20px
</style>
